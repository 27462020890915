import RoughnessTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-texture/roughness/RoughnessTemplate';

import { AMOUNT } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinTexture/Roughness/RoughnessTemplate',
  component: RoughnessTemplate
};

const createStory = props => () => ({
  components: { RoughnessTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><roughness-template v-bind="$options.storyProps" /></div>'
});

export const Base = createStory({
  skinRoughness: AMOUNT.CONSIDERABLY,
  exfoliateSkin: 'yes',
  chemicalPeeling: 'no'
});
